import RequestService from './Request'

var activate = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user-product/' + id + '/activate',
			method: 'PUT',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

var deactivate = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user-product/' + id + '/deactivate',
			method: 'PUT',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

var getAll = () => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user-product',
			method: 'GET',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

var getAllData = () => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user-product/data',
			method: 'GET',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

var get = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user-product/' + id,
			method: 'GET',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

var search = (filterType = undefined, policyType = undefined, startDate = undefined,
	endDate = undefined, searchQuery = undefined, newestFirst = undefined,
	itemsPerPage = undefined, pageIndex = undefined) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user-product',
			method: 'POST',
			body: {
				filterType: filterType,
				policyType: policyType,
				startDate: startDate,
				endDate: endDate,
				searchQuery: searchQuery,
				newestFirst: newestFirst,
				pagination: itemsPerPage || pageIndex ? {
					itemsPerPage: itemsPerPage,
					pageIndex: pageIndex
				} : undefined
			},
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}


export default {
	activate, deactivate,
	get, getAll, search, getAllData
}
