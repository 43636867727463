import RequestService from './Request'

var activate = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user/' + id + '/activate',
			method: 'PUT',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

var deactivate = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user/' + id + '/deactivate',
			method: 'PUT',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

var setEmployee = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user/' + id + '/employee',
			method: 'PUT',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

var setNonEmployee = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user/' + id + '/non-employee',
			method: 'PUT',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

var getAll = () => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user',
			method: 'GET',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

var get = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user/' + id,
			method: 'GET',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

var search = (query = undefined, oldestFirst = undefined,
	itemsPerPage = undefined, pageIndex = undefined) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/user',
			method: 'POST',
			body: {
				query: query,
				oldestFirst: oldestFirst,
				pagination: itemsPerPage || pageIndex ? {
					itemsPerPage: itemsPerPage,
					pageIndex: pageIndex
				} : undefined
			},
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}


export default {
	activate, deactivate,
	setEmployee, setNonEmployee,
	getAll, get, search
}
