import RequestService from './Request'

var getLatest = () => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/log',
			method: 'GET',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

var getLines = (lines = 100) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/log/' + lines,
			method: 'GET',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}


export default {
	getLatest, getLines
}
