import RequestService from './Request'

const getMaintenanceStatus = () => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/system/maintenance',
			method: 'GET',
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

const setMaintenanceStatus = status => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/system/maintenance',
			method: 'PUT',
			body: {
				status: status
			},
			addToken: true
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

export default {
	getMaintenanceStatus, setMaintenanceStatus
}
